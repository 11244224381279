<template>
  <b-container>
    <h1>Preferences</h1>
        <b-form-checkbox
          id="checkbox-show-auto-tag-badge"
          v-model="showAutoTagsBadge"
          :value="true"
          :unchecked-value="false"
        >
          show auto tags badge
        </b-form-checkbox>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'

export default {
  computed: {
    showAutoTagsBadge: {
      get () {
        return this.$store.state.showAutoTagsBadge
      },
      set (payload) {
        this.$store.commit('setShowAutoTagsBadge', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "Preferences"
    if (!ac.can(this.user.acgroups).readAny('rricpanel').granted) {
      this.$router.push({ name: 'Start' })
    }
    this.$stat.log({ page: 'admin preferences', action: 'open page' })
  }
}
</script>

<style>
</style>
